import styled from 'styled-components';
import {BGImageCenter, BLACK, ellipsisLines, DARK_RED, SHADOW_COLOR, tabletMedia} from '../../globalVariables/GlobalVariables';
import {btn} from '../button/btn/Btn.css';
import {Close} from '../close/Close.css';

export const message = styled.div<{ image?: string; color?: string}>`
    position: relative;
    height: 0px;
    overflow: hidden;
    &.show{
        height: 160px;
        background: ${props => props.color};
        padding-block: 20px;
        padding-inline-start: 16%;
        padding-inline-end: 47px;
        border-radius: 15px;
        box-shadow: 0px 2px 7px 1px ${SHADOW_COLOR};
        &::before{
            content: '';
            display: block;
            position: absolute;
            inset-inline-start: 0px;
            inset-block-start: 0px;
            height: 100%;
            width: 18%;
            ${p=>BGImageCenter(p.image, 'contain')}
            ${tabletMedia}{
                width: 30%;
            }
        }
        ${tabletMedia}{
            padding-inline-end: 23px;
            text-align: center;
            height: max-content;
            display: block;
            padding-inline-start: 30%;
        }
    } 
    transition: all 400ms;
    ${Close}{
        position: absolute;
        inset-block-start: 5px;
        inset-inline-start: 5px;
    }
`;

export const title = styled.div`
    color: ${BLACK};
    font-family: 'Noto Sans Hebrew';
    font-weight: 900;
    line-height: 47.894px;
    font-size: ${p => p.theme.rtl? '40': '30'}px;
    ${ellipsisLines(1)}
    ${tabletMedia}{
        font-size: 24px;
        width: 100%;
        text-align: start;
        font-weight: 900;
        line-height: 89.7%;   
        ${ellipsisLines(2)}
    }
    >span{
        font-family: 'Noto Sans Hebrew';
        color: ${DARK_RED};
    }
`;
export const flexWrap = styled.div<{buttonColor?: string}>`
    display: flex;
    justify-content: space-between;
    ${tabletMedia}{
        display: block;
    }
    ${btn}{
        font-family: 'Noto Sans Hebrew';
        padding: 12px 24px;
        font-size: 18px;
        height: 48px;
        inset-block-end: 10px;
        font-weight: 700;
        &:hover{
            background-color: ${p=>p.buttonColor};
            border-color: ${p=>p.buttonColor};
        }
        ${tabletMedia}{
            height: 33px;
            padding: 8px 30px;
            font-size: 14px;
            inset-block-end: unset;
        }
    }
`;
export const text = styled.div`
    margin-block: 10px;
    font-family: 'Noto Sans Hebrew';
    color: ${BLACK};
    text-align: start;
    font-size: ${p => p.theme.rtl? '21': '18'}px;
    font-weight: 500;
    line-height: 24.15px;
    max-width: 70%;
    ${ellipsisLines(3)}
    ${tabletMedia}{
        ${ellipsisLines(4)}
        max-width: 100%;
        width: 100%;
        font-size: 12px;
        line-height: 13.2px;
    }
`;
